html, body, #root
  height: 100%
  //font-family: Inter, Roboto, Helvetica, Arial, sans-serif
body
  overflow-y: overlay
  //color: red
  //background-color: #edeef0
.NavItem
  &-active
    background-color: rgb(10, 81, 82)








